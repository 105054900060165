
import { mapMutations } from 'vuex';
export default {
    name: "article-report-response",
    computed: {
        report_response(){
            return this.$store.state.report.blog_report_response; 
        }
    },
    methods: {
        ...mapMutations('report', [
            'toggleReportResponse'
        ]),
    }
}
