
import axios from "axios";
import { mapMutations } from 'vuex'; 
export default {
    name: "blogger-card",
    props: {
        item: {
            type: Object
        },
        pa_count: {
            type: Number
        },
        pa_idx: {
            type: Number
        }
    },
    data() {
        return {
            followed_status: this.item.followed
        }
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        }
    },
    methods: {
        ...mapMutations('lightbox', [
            'setViewData',
        ]),
        followBlogger(id) {
            if ( this.is_login && this.member_token!= null ) {
                const reqInstance = axios.create({
                    headers: {
                        Authorization : this.member_token
                    }
                });
                const API_url = "https://www.pixnet.net/api/v1/member/following/" + id;    
                const req = reqInstance.put(API_url);
                req.then((e)=>{
                    if(!e.data.error && e.status === 200) {
                        this.followed_status = true;
                        this.setViewData("follow");
                        this.openToastElement();
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        unfollowBlogger(id) {
            if ( this.is_login && this.member_token!= null ) {
                const reqInstance = axios.create({
                    headers: {
                        Authorization : this.member_token
                    }
                });
                const API_url = "https://www.pixnet.net/api/v1/member/following/" + id;    
                const req = reqInstance.delete(API_url);
                req.then((e)=>{
                    if(!e.data.error && e.status === 200) {
                        this.followed_status = false;
                        this.setViewData("unfollow");
                        this.openToastElement();
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        toggleFollowBlogger(status, id){
            this.paFollowFunction(this.item.displayname, this.item.user_name)
            
            if(status) {
                this.unfollowBlogger(id);
            } else {
                this.followBlogger(id);
            }
        },
        openToastElement(){
            this.$parent.openToast();
        },
        paClickFunction(name, id) {
            let idx = (this.pa_count - 1) * 9 + this.pa_idx;
            let idx_input = idx/10000;
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'click::開始關注有興趣的部落客::blogger_card::'+ this.model_id, '開始關注有興趣的部落客::' + name + "/" + id, this.pa_count + idx_input]);
        },
        paFollowFunction(name, id) {
            let idx = (this.pa_count - 1) * 9 + this.pa_idx;
            let idx_input = idx/10000;
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'follow::開始關注有興趣的部落客::blogger_card::'+ this.model_id, '開始關注有興趣的部落客::' + name + "/" + id, this.pa_count + idx_input]);
        }
    },
    watch: {
        item: function() {
            this.followed_status = this.item.followed
        }
    }
}
