
export default {
    name: "toast-short-container",
    data() {
        return {
            public_toast: ''
        }
    },
    methods: {
        openToastElement(){
            this.public_toast.show();
        }
    },
    mounted() {
        this.public_toast = this.$getCollectToast(this.$refs.public_toast_short);
    }
}
