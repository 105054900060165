
import ToastContainer from '../lightbox/ToastContainer.vue';
import BloggerCard from './BloggerCard.vue';
import ArticleCard from '../article/ArticleCard.vue';
import ShortArticleCard from '../article/ShortArticleCard.vue';
import ArticleReport from '../article/lightbox/ArticleReport.vue';
import ArticleReportResponse from '../article/lightbox/ArticleReportResponse.vue';
import ArticleShare from '../article/lightbox/ArticleShare.vue';
export default {
    name: "blogger-login-container",
    components: { BloggerCard, ToastContainer, ArticleCard, ArticleReport, ArticleReportResponse, ArticleShare, ShortArticleCard },
    data(){
        return {
            blogger_data: [],
            pa_count: 1,
            personal_river_count: 1
        }
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        blogger_login_data() {
            return this.$store.state.blogger.blogger_login_data.slice(0, 9);
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        model_id() {
            return this.$store.state.blogger.blogger_login_follow_model_id;
        },
        followed_blogger_article() {
            return this.$store.state.article.followed_blogger_article;
        },
        personal_river() {
            return this.$store.state.article.personal_river
        },
        odd_column_data() {
            return this.personal_river.filter((v, i) => !(i % 2));
        },
        even_column_data() {
            return this.personal_river.filter((v, i) => i % 2);
        },
    },
    methods:{
        async randomList(){
            let page_size = this.personal_river.length > 0 ? 3 : 9;
            const params = { member_token: this.member_token, with_blog: 1, page_size: page_size, page: this.pa_count + 1 };
            await this.$store.dispatch("blogger/getBloggerLoginData", params);
            this.blogger_data = this.blogger_login_data;
            this.pa_count = this.pa_count + 1;
            this.blogger_data.forEach((ele, index)=>{
                // 計算pa_count＊9的倍數，上面先加過了所以這邊要減回來
                if(this.is_login) {
                    this.paFunction(ele.displayname, ele.user_name, ((this.pa_count - 1) * 3) + index + 1);
                } else {
                    this.paFunction(ele.displayname, ele.user_name, ((this.pa_count - 1) * 9) + index + 1);
                }
            })
        },
        openToast(){
            this.$refs.toast_box.openToastElement();
        },
        paFunction(name, id, idx) {
            let pa_idx = idx/10000;
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'imps::開始關注有興趣的部落客::blogger_card::'+ this.model_id, '開始關注有興趣的部落客::' + name + "/" + id, this.pa_count + pa_idx]);
        },
        async getMoreFollowedBloggerArticle() {
            this.personal_river_count = this.personal_river_count + 1;
            await this.$store.dispatch("article/getMoreArticleByPersonalRiverByModel", { member_token: this.member_token, page: this.personal_river_count, with_personal: 1});
        }
    },
    async mounted(){
        const params = { member_token: this.member_token, with_blog: 1, page_size: 9, page: 1 };
        await this.$store.dispatch("blogger/getBloggerLoginData", params);
        if(this.is_login) {
            await this.$store.dispatch("article/getFollowedBloggerArticleDataByMemberToken", { member_token: this.member_token, with_author: 1, with_blog: 1});
            await this.$store.dispatch("article/getArticleByPersonalRiverByModel", { member_token: this.member_token, page: 1, with_personal: 1});
        }
        this.blogger_data = this.blogger_login_data;
        this.blogger_data.forEach((ele, index)=>{
            this.paFunction(ele.displayname, ele.user_name, index + 1);
        })
        
    }
}
