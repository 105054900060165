
import { mapMutations } from 'vuex'; 
import ToastShortContainer from '../../lightbox/ToastShortContainer.vue';
export default {
    name: "article-share",
    computed: {
        url_link() {
            return this.$store.state.share.share_link;
        },
        url_title() {
            return this.$store.state.share.share_title;
        },
        open_share() {
            return this.$store.state.share.open_share;
        }
    },
    methods: {
        ...mapMutations("share", [
            "toggleShareOpen"
        ]),
        shareToSocialSite(site) {
            if (site == "facebook") {
                window.open("http://www.facebook.com/share.php?u=".concat(this.url_link));
            }
            else if (site == "twitter") {
                window.open("https://twitter.com/intent/tweet?text=" + encodeURI(this.url_title) + "&url=" + encodeURI(this.url_link));
            }
            else if (site == "plurk") {
                window.open("http://www.plurk.com/?qualifier=shares&status=".concat(this.url_link).concat(" ").concat("(").concat(encodeURIComponent(this.url_title)).concat(")"));
            }
            else {
                window.open("line://msg/text/" + this.url_link, "_blank");
            }
        },
        copyLink() {
            const unsecuredCopyToClipboard = (text) => {
                const textArea = document.createElement("textarea");
                textArea.value = text;
                textArea.style.position = "fixed";
                textArea.style.left = "-999999px";
                textArea.style.top = "-999999px";
                document.body.appendChild(textArea);
                textArea.select();
                try {
                    document.execCommand("copy");
                }
                catch (err) {
                    console.error("Unable to copy to clipboard", err);
                }
                document.body.removeChild(textArea);
            };
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(this.url_link);
            }
            else {
                unsecuredCopyToClipboard(this.url_link);
            }
            this.$refs.toast_short_box.openToastElement();
        }
    },
    components: { ToastShortContainer }
}
