
import axios from "axios";
import { mapMutations } from 'vuex'; 
export default {
    name: "article-card",
    props: {
        article: Object,
        model_id: String,
        pa_count: Number,
        article_idx: Number,
        pa_page: String,
        close_utils: Boolean
    },
    data() {
        return {
            collect_status: this.article?.collect ? this.article?.collect : this.article?.relation?.collect,
            like_status: this.article?.like ?this.article?.like: this.article?.relation?.like,
            show_this_article: true,
            isVisible: true,
            like_count: this.article?.like_count ? this.article?.like_count : this.article?.counts?.like_count,
            block_click_status: false,
            pa_action_label_list: {
                '首頁': {
                    e_action: '推薦',
                    e_label: '文章',
                },
                '旅遊/國外': {
                    e_action: '旅遊文章',
                    e_label: '旅遊文章',
                },
                '旅遊/國內': {
                    e_action: '旅遊文章',
                    e_label: '旅遊文章',
                },
                '美食': {
                    e_action: '美食文章',
                    e_label: '美食文章',
                },
                '影視': {
                    e_action: '影視文章',
                    e_label: '影視文章',
                },
                '親子': {
                    e_action: '親子文章',
                    e_label: '親子文章',
                },
                '美妝': {
                    e_action: '美妝文章',
                    e_label: '美妝文章',
                },
                '3C': {
                    e_action: '3C文章',
                    e_label: '3C文章',
                },
                '身心靈': {
                    e_action: '身心靈文章',
                    e_label: '身心靈文章',
                },
                '寵物': {
                    e_action: '寵物文章',
                    e_label: '寵物文章',
                },
                '健康': {
                    e_action: '健康文章',
                    e_label: '健康文章',
                },
                '職涯': {
                    e_action: '職涯文章',
                    e_label: '職涯文章',
                },
                '住家': {
                    e_action: '住家文章',
                    e_label: '住家文章',
                },
                '私康': {
                    e_action: '私康文章',
                    e_label: '私康文章',
                },
                '名片頁': {
                    e_action: '名片頁文章',
                    e_label: '名片頁文章',
                },
            },
            random_default_img: Math.floor(Math.random()*3)
        };
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        router_name() {
            return this.$route.name;
        },
        decode_title() {
            return decodeURIComponent(this.article.title);
        },
        router_name() {
            return this.$route.name;
        },
        article_date() {
            if (this.article.date) {
                return this.article.date;
            } else if (this.article.datetime) {
                const time = new Date(this.article.datetime)
                const month = time.getMonth() + 1;
                const date = time.getDate();
                return month.toString() + '/' + date.toString();
            }
        },
        profile_id() {
            if(this.article.author) {
                return this.article.author.user_name;
            } else {
                return this.$route.params.id;
            }
        },
    },
    methods: {
        ...mapMutations('share', [
            'setShareLink',
            'setShareTitle',
            'toggleShareOpen',
        ]),
        ...mapMutations('report', [
            'toggleReport',
            'updateArticleId',
            'updateArticleLink',
            'updateArticleMemberId'
        ]),
        ...mapMutations('lightbox', [
            'setViewData',
        ]),
        openShare(link, title){
            this.setShareLink(link);
            this.setShareTitle(title);
            this.toggleShareOpen()
        },
        async openReport(id, link, user_name) {
            this.paClickReportFunction();
            if(this.is_login && this.member_token) {
                this.updateArticleMemberId(user_name)
                this.updateArticleId(id);
                this.updateArticleLink(link);
                this.toggleReport();
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
            
        },
        collectArticle(article, author_name){
            if ( this.is_login && this.member_token!= null ) {
                const header = { 'memberauth': this.member_token }
                const API_url = "https://"+ author_name +".pixnet.net/api/blog/collections";
                const req = axios.post(API_url, { headers: header, object_ids: { article: [article] } , responseType: "json" });
                req.then((e) => {
                    if(!e.data.error && e.status === 200) {
                        this.collect_status = true;
                        this.setViewData("collect");
                        this.openToastElement();
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        discollectArticle(article, author_name){
            if ( this.is_login && this.member_token!= null ) {
                const header = { 'memberauth': this.member_token }
                const API_url = "https://"+ author_name +".pixnet.net/api/blog/collections";
                const req = axios.delete(API_url, { 
                    data: {
                        headers: header,
                        object_ids: [article],
                        type: "article" 
                    }
                });
                req.then((e) => {
                    if(!e.data.error && e.status === 200) {
                        this.collect_status = false;
                        this.setViewData("uncollect");
                        this.openToastElement();
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        toggleCollectArticle(status, article, author_name){
            this.paClickCollectFunction()
            if(status) {
                this.discollectArticle(article, author_name);
            } else {
                this.collectArticle(article, author_name);
            }
        },
        likeArticle(){
            if ( this.is_login && this.member_token!= null ) {
                const API_url = "https://www.pixnet.net/mainpage/api/pushes";
                const object_ids =  { 
                    service: 'blog-article', 
                    comment_id: this.article.author.uniqid + '-blog-article://members/'+ this.article.author.uniqid +'/articles/'+ this.article.id +'-' + this.article.id, 
                    push_count: 1
                }
                const reqInstance = axios.create({
                    headers: {
                        Authorization : "Bearer "+ this.member_token,
                    }
                });
                const req = reqInstance.post(API_url, object_ids);
                req.then((e) => {
                    if(!e.data.error && e.status === 200) {
                        this.like_status = true;
                        this.like_count = parseInt(this.like_count) + 1;
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        disLikeArticle(){
            if ( this.is_login && this.member_token!= null ) {
                const API_url = "https://www.pixnet.net/mainpage/api/pushes";
                const params = { 
                    service: 'blog-article', 
                    comment_id: this.article.author.uniqid + '-blog-article://members/'+ this.article.author.uniqid +'/articles/'+ this.article.id +'-' + this.article.id, 
                }
                const headers =  {
                    Authorization : "Bearer "+ this.member_token,
                }
                const req = axios.delete(API_url ,{ headers: headers, params: params, responseType: "json" });
                req.then((e) => {
                    if(!e.data.error && e.status === 200) {
                        this.like_status = false;
                        this.like_count = parseInt(this.like_count) - 1;
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        setBlockToAvoidTooMoreClick() {
            this.block_click_status = true;
            setTimeout(()=>{
                this.block_click_status = false;
            }, 1000)
        },
        toggleLikeArticle(status){
            this.setBlockToAvoidTooMoreClick();
            this.paClickLikeFunction();
            if(status) {
                this.disLikeArticle();
            } else {
                this.likeArticle();
            }
        },
        openToastElement(){
            this.$parent.openToast();
        },
        disableThisArticle() {
            this.show_this_article = !this.show_this_article;
        },
        paFunction(){
            let pa_idx = (this.pa_count + (this.article_idx + 1)/10000).toFixed(4);
            let reg_article_link = "http://"+ this.profile_id +".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `imps::${this.pa_action_label_list[this.pa_page].e_action}::article_card::${this.model_id}`,
                `${this.pa_action_label_list[this.pa_page].e_label}::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickFunction(){
            let pa_idx = (this.pa_count + (this.article_idx + 1)/10000).toFixed(4);
            let reg_article_link = "http://"+ this.profile_id +".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `click::${this.pa_action_label_list[this.pa_page].e_action}::article_card::${this.model_id}`,
                `${this.pa_action_label_list[this.pa_page].e_label}::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickLikeFunction(){
            let pa_idx = (this.pa_count + (this.article_idx + 1)/10000).toFixed(4);
            let reg_article_link = "http://"+ this.profile_id +".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `happix::${this.pa_action_label_list[this.pa_page].e_action}::article_card::${this.model_id}`,
                `${this.pa_action_label_list[this.pa_page].e_label}::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickCollectFunction(){
            let pa_idx = (this.pa_count + (this.article_idx + 1)/10000).toFixed(4);
            let reg_article_link = "http://"+ this.profile_id +".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `collect::${this.pa_action_label_list[this.pa_page].e_action}::article_card::${this.model_id}`,
                `${this.pa_action_label_list[this.pa_page].e_label}::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickShareFunction(){
            let pa_idx = (this.pa_count + (this.article_idx + 1)/10000).toFixed(4);
            let reg_article_link = "http://"+ this.profile_id +".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `share::${this.pa_action_label_list[this.pa_page].e_action}::article_card::${this.model_id}`,
                `${this.pa_action_label_list[this.pa_page].e_label}::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickReportFunction(){
            let pa_idx = (this.pa_count + (this.article_idx + 1)/10000).toFixed(4);
            let reg_article_link = "http://"+ this.profile_id +".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `morelist::${this.pa_action_label_list[this.pa_page].e_action}::article_card::${this.model_id}`,
                `${this.pa_action_label_list[this.pa_page].e_label}::${reg_article_link}`,
                pa_idx
            ]);
        },
        imgOnError(event) {
            event.target.src = 'https://www.pixnet.net/img/public/default/img_default_'+ this.random_default_img +'.png';
        },
        visibilityChanged(isVisible, entry) {
            this.isVisible = isVisible
            if (entry.intersectionRatio > 0) {
                this.paFunction()  
            }
        }
    },
    mounted() {
    }
};
